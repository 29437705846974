if (document.querySelector(".recommended .swiper")) {

    var swiper = new Swiper(".recommended .swiper", {
        lazy: true,

        navigation: {
            nextEl: ".recommended .swiper-button-next",
            prevEl: ".recommended .swiper-button-prev",
        },
    });

}

if (document.querySelector(".teacher .swiper")) {

    var swiper = new Swiper(".teacher .swiper", {
        lazy: true,
        slidesPerView: 1,
        spaceBetween: 15,

        navigation: {
            nextEl: ".teacher .swiper-button-next",
            prevEl: ".teacher .swiper-button-prev",
        },
        breakpoints: {
            640: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            992: {
                slidesPerView: 4,

            },
        },
    });
}


if (document.querySelector(".tabProduct__container .swiper")) {

    var swiper = new Swiper(".tabProduct__container .swiper", {
        lazy: true,
        slidesPerView: 1,
        spaceBetween: 15,

        navigation: {
            nextEl: ".tabProduct__container .swiper-button-next",
            prevEl: ".tabProduct__container .swiper-button-prev",
        },
        breakpoints: {
            576: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,

            },
        },
    });
}
